import { ConnectedRouter } from 'connected-next-router'
import { ReactNode } from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import { SWRConfig } from 'swr'

import { BottomSheetProvider } from '@context/BottomSheetContext'
import { CommandContextProvider } from '@context/CommandContext'
import { CurrentUserContextProvider } from '@context/CurrentUserContext'
import { GlobalContextProvider } from '@context/GlobalContexts'
import { ProgressBarProvider } from '@context/ProgressBarContext'
import { SessionManagementProvider } from '@context/SessionManagementContext'
import { SidePanelProvider } from '@context/SidePanelContext'
import { ThemeProvider } from '@context/ThemeContext'
import { BrowserInfo } from '@lib/browser'
import { commandContextSWRMiddleware } from '@lib/commands/commandContextSWRMiddleware'
import { gtWalsheim, sharpSans } from '@lib/fonts'
import runtimeConfig from '@lib/RuntimeConfig'
import InternalServerErrorPage from '@pages/500.page'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { AppStore } from '@redux/store/store'
import { MessageServiceProvider } from '@tracking/context/MessageServiceContext'
import ErrorBoundary from './ErrorBoundary'

interface AppProvidersProps {
  children: ReactNode
  store: AppStore
  browserInfo: BrowserInfo
  isLoggedIn: boolean
  pageName?: string
}

export const AppProviders = ({ children, store, browserInfo, isLoggedIn, pageName }: AppProvidersProps) => {
  return (
    <main className={`${gtWalsheim.variable} ${sharpSans.variable} font-sans`}>
      <ThemeProvider>
        <ReduxProvider store={store}>
          <ConnectedRouter>
            <GlobalContextProvider browserInfo={browserInfo} pageName={pageName}>
              <CommandContextProvider>
                <SessionManagementProvider>
                  <CurrentUserContextProvider isLoggedInFromCookies={isLoggedIn}>
                    <SWRConfig value={{ use: [commandContextSWRMiddleware] }}>
                      <GoogleOAuthProvider clientId={runtimeConfig().publicRuntimeConfig.auth.google.clientID}>
                        <MessageServiceProvider>
                          <ErrorBoundary fallbackComponent={<InternalServerErrorPage disableTracking={false} />}>
                            <SidePanelProvider>
                              <BottomSheetProvider>
                                <ProgressBarProvider>{children}</ProgressBarProvider>
                              </BottomSheetProvider>
                            </SidePanelProvider>
                          </ErrorBoundary>
                        </MessageServiceProvider>
                      </GoogleOAuthProvider>
                    </SWRConfig>
                  </CurrentUserContextProvider>
                </SessionManagementProvider>
              </CommandContextProvider>
            </GlobalContextProvider>
          </ConnectedRouter>
        </ReduxProvider>
      </ThemeProvider>
    </main>
  )
}
